import IService from './iservice';
import config from "@/config";
import utils from '@/helpers/utils';

const server_base = config[config.stage].ws_server;

class BusinessCardService extends IService {
  constructor(http) {
    super(http);
  }

  async create(agent) {
    try {
      const response = await this.http.post(`${server_base}/api/business-card`, {agent});
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async get(id) {
    try {
      const response = await this.http.get(`${server_base}/api/business-card/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async active(card) {
    try {
      const response = await this.http.put(`${server_base}/api/business-card/active`, card);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(id) {
    try {
      const response = await this.http.delete(`${server_base}/api/business-card/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip, filters = {}) {
    try {
      const query = {};

      if (limit) query.limit = limit;
      if (skip) query.skip = skip;
      if (filters.company) query.company = filters.company;
      if (filters.agent) query.agent = filters.agent;
      if (filters.termsAccepted) query.termsAccepted = filters.termsAccepted;
      if (filters.search) {
        query.search = utils.isUsPhone(filters.search) ? utils.NormalizeNumber(filters.search) : filters.search;
      }

      const response = await this.http.get(`${server_base}/api/business-card`, query);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default BusinessCardService;
