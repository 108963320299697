import { render, staticRenderFns } from "./BusinessCard.html?vue&type=template&id=342556a1&scoped=true&external"
import script from "./BusinessCard.js?vue&type=script&lang=js&external"
export * from "./BusinessCard.js?vue&type=script&lang=js&external"
import style0 from "./BusinessCard.scss?vue&type=style&index=0&id=342556a1&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342556a1",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FBusinessCard%2Findex.vue&scope=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports