import config from "@/config";
import HttpClient from "@/helpers/http.client";
import BusinessCardService from '@/services/business.card.service';

const
  http = new HttpClient(),
  server_base = config[config.stage].ws_server,
  service = new BusinessCardService(http);
export default {
  name: 'business-card',
  components: {},
  props: [],
  data() {
    return {
      cards: [],
      count: 0,
      perPage: 50,
      totalRows: 0,
      currentPage: 1,
    }
  },
  computed: {},
  async mounted() {
    await this.list();
  },
  methods: {
    async list(page) {
      this.currentPage = page || 1;

      let skip = this.perPage * (this.currentPage - 1);

      const res = await service.list(this.perPage, skip);
      if (res && !res.error) {
        this.cards = res.data.cards;
        this.count = res.data.count;
      }
    },

    async handleActiveCard(value, id) {
      const record = {active: value, card: id}

      const res = await service.active(record);
      if (res && !res.error) {
        const notify_message = value ? this.$t('card_active') : this.$t('card_inactive')
        this.$notify({
          type: 'success',
          message: notify_message
        });
      }
    },

    userAvatar(img) {
      return `${server_base}/api/files/avatar/${img}`;
    },
  }
}


